import { default as _404wD2oqHnfryMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/404.vue?macro=true";
import { default as _500Kbqe8ikt4aMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/500.vue?macro=true";
import { default as forgot_45passwordkvkZDRxRHAMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/auth/forgot-password.vue?macro=true";
import { default as register_45with_45otpfpY0px288vMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/auth/register-with-otp.vue?macro=true";
import { default as reset_45passwordkkTpc4taIwMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/auth/reset-password.vue?macro=true";
import { default as sign_45injJtsdp8PV8Meta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/auth/sign-in.vue?macro=true";
import { default as authxqoJ6JFHBwMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/auth.vue?macro=true";
import { default as _91id_93sG5UIbGSbnMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/booking-v2/[id].vue?macro=true";
import { default as indexOhMLF6VBqGMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/booking-v2/index.vue?macro=true";
import { default as _91id_933UiqW06DP9Meta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/booking/[id].vue?macro=true";
import { default as indexcBGYO3CvmCMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/booking/index.vue?macro=true";
import { default as _91clinicId_93QNqAbt7RECMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/clinic/[clinicId].vue?macro=true";
import { default as indexAxWM4vvNNlMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/clinic/index.vue?macro=true";
import { default as dashboard1N62paF5i5Meta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/dashboard.vue?macro=true";
import { default as _91id_93qG8SR4ERuIMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/doctor/[id].vue?macro=true";
import { default as indexWGXuw0E7oWMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/index.vue?macro=true";
import { default as maintenanceqxWOLHtFLgMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/maintenance.vue?macro=true";
import { default as _91id_93HN4gCT6zRvMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/question/[id].vue?macro=true";
import { default as add72y8ULUPgPMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/question/add.vue?macro=true";
import { default as index4kUkCxcrBkMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/question/index.vue?macro=true";
import { default as _91slug_93xNzZs3HVyTMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/tin-tuc/[slug].vue?macro=true";
import { default as index2umMl5HBUPMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/tin-tuc/index.vue?macro=true";
import { default as _91id_93dIDWzFMzjPMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/wv/question/[id].vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    meta: _404wD2oqHnfryMeta || {},
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    meta: _500Kbqe8ikt4aMeta || {},
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: authxqoJ6JFHBwMeta || {},
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/auth.vue").then(m => m.default || m),
    children: [
  {
    name: "auth-forgot-password",
    path: "forgot-password",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-register-with-otp",
    path: "register-with-otp",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/auth/register-with-otp.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "reset-password",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-sign-in",
    path: "sign-in",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/auth/sign-in.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "booking-v2-id",
    path: "/booking-v2/:id()",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/booking-v2/[id].vue").then(m => m.default || m)
  },
  {
    name: "booking-v2",
    path: "/booking-v2",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/booking-v2/index.vue").then(m => m.default || m)
  },
  {
    name: "booking-id",
    path: "/booking/:id()",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/booking/[id].vue").then(m => m.default || m)
  },
  {
    name: "booking",
    path: "/booking",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/booking/index.vue").then(m => m.default || m)
  },
  {
    name: "clinic-clinicId",
    path: "/clinic/:clinicId()",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/clinic/[clinicId].vue").then(m => m.default || m)
  },
  {
    name: "clinic",
    path: "/clinic",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/clinic/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "doctor-id",
    path: "/doctor/:id()",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/doctor/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexWGXuw0E7oWMeta || {},
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceqxWOLHtFLgMeta || {},
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "question-id",
    path: "/question/:id()",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/question/[id].vue").then(m => m.default || m)
  },
  {
    name: "question-add",
    path: "/question/add",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/question/add.vue").then(m => m.default || m)
  },
  {
    name: "question",
    path: "/question",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/question/index.vue").then(m => m.default || m)
  },
  {
    name: "tin-tuc-slug",
    path: "/tin-tuc/:slug()",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/tin-tuc/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tin-tuc",
    path: "/tin-tuc",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: "wv-question-id",
    path: "/wv/question/:id()",
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/pages/wv/question/[id].vue").then(m => m.default || m)
  }
]