import validate from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.50_eslint@8.57.0_ioredis@5.4.1_magicast@0_bowb55cx2v63qu5m7fdcfyisvi/node_modules/nuxt/dist/pages/runtime/validate.js";
import app_45webview_45global from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/middleware/appWebview.global.ts";
import manifest_45route_45rule from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.50_eslint@8.57.0_ioredis@5.4.1_magicast@0_bowb55cx2v63qu5m7fdcfyisvi/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  app_45webview_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/middleware/auth.ts"),
  guest: () => import("/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/middleware/guest.ts")
}